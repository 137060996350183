import { current } from '@reduxjs/toolkit';
import { createIntl, createIntlCache } from 'react-intl';
import {
    calculateDrawingCoordinates,
    calculateOperatingDevices,
    calculateOverallLumenAndWatt,
    calculatePrices,
    calculateProfileDrawingCoordinates,
    getCorrectProfileLengths,
    mapElementsToProducts,
} from './productsSlice';
import { test2400, test3450, test4350, test9450 } from '../../tests/test';
import { imperativeTranslateMessage } from '../../components/Translator';
import german from '../../resources/de.json';
import english from '../../resources/en.json';
import french from '../../resources/fr.json';
import italian from '../../resources/it.json';
import { CS_RECESSED_CORNER_LEG, CS_STANDARD_CORNER_LEG } from './channels';
import { DEBUG } from '../../services/domainService';

/**
@typedef abstractLight
@property length
@property expandable
@property type
*/

/**
 * Calculates Purelite
 * @param state
 */
export function doCalculationPurelite(state) {
    let userLength = state.userLengths.line1;
    let rulesetConstants = current(state).constants;
    let lineLengths = JSON.parse(current(state).lineLengths);

    // resetting prices, profileLengths & diffusorLengths
    state.productPriceEur = 0;
    state.productPriceChf = 0;
    state.profileLengths = null;
    state.diffusorLengths = null;

    //defining arrays for calculating elements
    let elements = [];

    let master = lineLengths.filter((item) => item.type === 'master');
    let slave = lineLengths.filter((item) => item.type === 'slave');

    //sort arrays after line length desc
    master.sort((a, b) => b.length - a.length);
    slave.sort((a, b) => b.length - a.length);

    elements = calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);

    //save calculated elements to state
    state.lineElements = JSON.stringify(elements);

    mapElementsToProducts(state, elements);
    mapProfilesToProductsPurelite(state);

    mapEQPToProductsPurelite(state);
    mapSystemEquipmentToProductsPurelite(state);

    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateProfileDrawingCoordinates(state, false);
    }
    state.isSimplifiedDrawing = false;
    calculateProfileDrawingCoordinates(state, false);
    calculateOverallLumenAndWatt(state);
    calculateOperatingDevices(state, elements);
    state.isSimplifiedDrawing = true;
    if (current(state).isSimplifiedDrawing) {
        calculateDrawingCoordinates(state, elements, false);
    }
    state.isSimplifiedDrawing = false;
    calculateDrawingCoordinates(state, elements, false);
}
/**
 * Precalculates the type of light elements used (which are also shown on the right sidebar)
 *
 * @param {*} state The React app sate
 * @param {string} userLength Input of the length of the line by the user (string of a number)
 * @param {Object} rulesetConstants Constants used specifically by PURELITE defined by backend
 * @param {array} master An array with the list of possible abstract master lighting of PURELITE
 * @param {array} slave An array with the list of possible abstract slave lighting of PURELITE
 * @param {array} elements An array of abstract lightings object for this line
 * @param {*} [lightLength=null]
 */
function calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements, lightLength = null) {
    let pureliteLengths = calculateLengthsPurelite(state, userLength, rulesetConstants, lightLength);
    lightLength = pureliteLengths.lightLength;
    // Retrieve sidebar value
    let sidebarValues = displayValues(
        'L1',
        pureliteLengths.userLength,
        pureliteLengths.recessLength,
        pureliteLengths.profileLength,
        0,
        pureliteLengths.diffusorLength,
        lightLength,
        pureliteLengths.totalLength,
        state
    );

    let currentLineValues = JSON.parse(state.lineValues) || [];
    currentLineValues.push(JSON.stringify(sidebarValues));
    state.lineValues = JSON.stringify(currentLineValues);

    let slaveCount = 0;
    let masters = {
        1500: 0,
        1350: 0,
        1200: 0,
        750: 0,
    };
    let ends = {
        1500: 0,
        1200: 0,
        750: 0,
    };

    // Align length to be a multiple of 150mm
    lightLength = lightLength - (lightLength % 150);

    if (lightLength < 2400) {
        slaveCount = 0;
    } else if (lightLength < 3750) {
        slaveCount = 1;
    } else if (lightLength < 5100) {
        slaveCount = 2;
    } else {
        slaveCount = Math.ceil((lightLength - 4950) / 2100) + 2;
    }

    // Add slave at start
    // slaveCount += 1

    const value = Math.ceil(lightLength - 3000 - (slaveCount - 2) * 2100);

    function sortElements(slaveCount, masters, ends, master, slave) {
        let assembly = [];
        // slaveCount -= 1;
        assembly.push(slave[0]);
        slaveCount -= 1;

        for (let key in masters) {
            let masterElement = master.filter((item) => item.length === parseInt(key) && item.expandable === true);
            masterElement = masterElement[0];
            while (masters[key] > 0) {
                assembly.push(masterElement);
                masters[key] -= 1;
                if (slaveCount > 0) {
                    assembly.push(slave[0]);
                    slaveCount -= 1;
                }
            }
        }

        for (let key in ends) {
            let endElement = master.filter((item) => item.length === parseInt(key) && item.expandable === false);
            endElement = endElement[0];
            while (ends[key] > 0) {
                assembly.push(endElement);
                ends[key] -= 1;
            }
        }
        return assembly;
    }

    function master750(value, masters, totalLength, slaveCount) {
        if (slaveCount < 2)
            if (totalLength === 2250 || totalLength === 2550 || totalLength === 2850) {
                masters[750] = 1;
            } else {
                masters[750] = 0;
            }
        else {
            if (value < 600 || value > 1200) {
                masters[750] = 1;
            } else {
                masters[750] = 0;
            }
        }
    }

    function master1200(value, masters, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 2400 || totalLength === 3000 || totalLength === 3300) {
                masters[1200] = 1;
            } else {
                masters[1200] = 0;
            }
        } else {
            if (value === 600) {
                masters[1200] = 2;
            } else if (value === 150 || value === 600 || value === 900 || value === 1350 || value === 1650) {
                masters[1200] = 1;
            } else {
                masters[1200] = 0;
            }
        }
    }

    function master1350(value, masters, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 2700 || totalLength === 3150 || totalLength === 3450) {
                masters[1350] = 1;
            } else {
                masters[1350] = 0;
            }
        } else {
            if (value === 0 || value === 300 || value === 750 || value === 1050 || value === 1500 || value === 1800) {
                masters[1350] = 1;
            } else {
                masters[1350] = 0;
            }
        }
    }

    function master1500(value, masters, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 3600) {
                masters[1500] = 1;
            } else {
                masters[1500] = 0;
            }
        } else {
            let count = 0;
            if (value === 1200 || value === 1950) {
                count = 2;
            } else if (value === 450 || value === 750 || value === 900 || value === 1050 || (value > 1200 && value < 1950)) {
                count = 1;
            } else {
                count = 0;
            }
            masters[1500] = count + slaveCount - 3;
        }
    }

    function end750(value, ends, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 2700) {
                ends[750] = 1;
            } else {
                ends[750] = 0;
            }
        } else {
            ends[750] = 0;
        }
    }

    function end1200(value, ends, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 2400 || totalLength === 2550 || totalLength === 3000 || totalLength === 3150) {
                ends[1200] = 1;
            } else {
                ends[1200] = 0;
            }
        } else {
            if (value === 0 || value === 750 || value === 1350 || value === 1500) {
                ends[1200] = 1;
            } else {
                ends[1200] = 0;
            }
        }
    }

    function end1500(value, ends, totalLength, slaveCount) {
        if (slaveCount < 2) {
            if (totalLength === 2250 || totalLength === 2850 || totalLength > 3150) {
                ends[1500] = 1;
            } else {
                ends[1500] = 0;
            }
        } else {
            if (!(value === 0 || value === 750 || value === 1350 || value === 1500)) {
                ends[1500] = 1;
            } else {
                ends[1500] = 0;
            }
        }
    }

    // Calculate master
    master750(value, masters, lightLength, slaveCount);
    master1200(value, masters, lightLength, slaveCount);
    master1350(value, masters, lightLength, slaveCount);
    master1500(value, masters, lightLength, slaveCount);
    // Calculate ends
    end750(value, ends, lightLength, slaveCount);
    end1200(value, ends, lightLength, slaveCount);
    end1500(value, ends, lightLength, slaveCount);

    switch (lightLength) {
        case 2400:
            test2400(value, slaveCount, masters, ends);
            break;
        case 3450:
            test3450(value, slaveCount, masters, ends);
            break;
        case 4350:
            test4350(value, slaveCount, masters, ends);
            break;
        case 5400:
            // test2400(value, slaveCount, masters, ends);
            break;
        case 6450:
            // test2400(value, slaveCount, masters, ends);
            break;
        case 7350:
            // test2400(value, slaveCount, masters, ends);
            break;
        case 8400:
            // test2400(value, slaveCount, masters, ends);
            break;
        case 9450:
            test9450(value, slaveCount, masters, ends);
            break;
        case 10500:
            // test2400(value, slaveCount, masters, ends);
            break;

        default:
            break;
    }

    let construction = sortElements(slaveCount, masters, ends, master, slave);

    elements = construction;
    return elements;
}

/**
 * @typedef {Object} LengthStruct
 * @property {number} diffusorLength
 * @property {number} lightLength
 * @property {number} profileLength
 * @property {number} recessLength
 * @property {number} totalLength
 * @property {number} userLength
 */

/**
 * @param {*} state
 * @param {*} userLength
 * @param {*} rulesetConstants
 * @param {*} lightLength
 * @returns {LengthStruct} {diffusorLength, lightLength, profileLength, recessLength, totalLength, userLength}
 */
export function calculateLengthsPurelite(state, userLength, rulesetConstants, lightLength) {
    const profileConst = 3;
    const diffusorConst = 31; // Used for room lighting
    const diffusorPerMeterConst = 0.5;
    const frontEnd = 2.5; // At the end of side there must something to cover up
    const pureliteStep = 150; // Purelite can be planned for each 150mm

    userLength = parseFloat(userLength);
    lightLength = userLength - (userLength % pureliteStep);

    let profileLength = lightLength + profileConst;
    // =ROUND(E9+5+0,5*E9/1000;0)
    let recessLength = Math.floor(profileLength + frontEnd * 2 + (diffusorPerMeterConst * profileLength) / 1000);
    let diffusorLength = 0;
    let totalLength = 0;

    // lightLength = Math.floor(Math.floor(userLength / 1000 * 6.66666666666666) / 6.66666666666666 * 1000)
    if (state.userSelectedPureliteSlimLighting === 'raumstrahlend') {
        profileLength = lightLength + profileConst;
        diffusorLength = Math.ceil(profileLength + diffusorConst + (profileLength * diffusorPerMeterConst) / 1000);
        totalLength = diffusorLength;
    }
    if (state.userSelectedPureliteSlimLighting === 'direktstrahlend') {
        // Modifier defined in Purelite_Konfigurator_V1.6
        let diffusor_modifier = 0;
        if (profileLength / 1000 >= 0 && profileLength / 1000 < 5) {
            diffusor_modifier = 5;
        } else if (profileLength / 1000 >= 5 && profileLength / 1000 < 10) {
            diffusor_modifier = 7;
        } else if (profileLength / 1000 >= 10 && profileLength / 1000 < 15) {
            diffusor_modifier = 10;
        } else if (profileLength / 1000 >= 15 && profileLength / 1000 < 20) {
            diffusor_modifier = 12;
        } else if (profileLength / 1000 >= 15 && profileLength / 1000 < 40) {
            diffusor_modifier = 15;
        }
        totalLength = profileLength + 2 * frontEnd;
        diffusorLength = totalLength - 2 * diffusor_modifier;
    }
    if (userLength < totalLength) {
        recessLength -= 150;
        profileLength -= 150;
        diffusorLength -= 150;
        lightLength -= 150;
    }

    //return lightLength to update variable in parent export function
    return {
        diffusorLength: diffusorLength,
        lightLength: lightLength,
        profileLength: profileLength,
        recessLength: recessLength,
        totalLength: totalLength,
        userLength: userLength,
    };
}

function mapProfilesToProductsPurelite(state) {
    let profiles = JSON.parse(state.profiles);
    let profileLengths = getCorrectProfileLengths(state);
    let installation = state.userSelectedInstallation;
    let lighting = state.userSelectedPureliteSlimLighting;

    if (typeof profiles === 'object') {
        let profileIndexes = [];
        Object.keys(profiles).forEach((key, index) => {
            profileIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < profileIndexes.length; i++) {
            temp.push(profiles[profileIndexes[i]]);
        }
        profiles = temp;
    }

    // Johannes wants recessed installation with edge cover (CT) and pendulum as well as ceiling without any (TL)
    switch (installation) {
        case 'ceiling':
        case 'pendulum':
            installation = 'TL';
            break;
        case 'recessed':
            installation = 'CT';
            break;
        default:
            console.log('An unexpected installation type was selected. TYPE:' + installation);
            break;
    }
    if (lighting === 'raumstrahlend') {
        installation = 'TL';
    }

    profiles.sort((a, b) => b.length - a.length);

    let ctProfiles = profiles.filter((item) => item.article_code.includes('CT') && !item.article_code.includes('.V'));
    let tlProfilesPurelite = profiles.filter(
        (item) => item.article_code.includes('TL') && item.product_line === 'Purelite' && !item.article_code.includes('.V')
    );
    let tlProfilesPureliteD = profiles.filter(
        (item) => item.article_code.includes('TL') && item.product_line === 'Purelite D' && !item.article_code.includes('.V')
    );

    let ctProfileLengths = [];
    let tlProfilesPureliteLengths = [];
    let tlProfilesPureliteDLengths = [];

    ctProfiles.map((item) => ctProfileLengths.push(item.length));
    tlProfilesPurelite.map((item) => tlProfilesPureliteLengths.push(item.length));
    tlProfilesPureliteD.map((item) => tlProfilesPureliteDLengths.push(item.length));

    let ctZuschnittProfiles = profiles.filter((item) => item.article_code.includes('CT') && item.article_code.includes('.V'));
    let tlZuschnittProfiles = profiles.filter(
        (item) => item.article_code.includes('TL') && item.product_line === 'Purelite' && item.article_code.includes('.V')
    );
    let tlDZuschnittProfiles = profiles.filter(
        (item) => item.article_code.includes('TL') && item.product_line === 'Purelite D' && item.article_code.includes('.V')
    );

    let profileProducts = [];

    for (let i = 0; i < profileLengths.length; i++) {
        let profile = profileLengths[i];
        let rest = profile.profile;
        let redoCalculation500Smaller = false;
        let add500 = false;
        let neededProfiles = installation === 'CT' ? ctProfiles : lighting === 'direktstrahlend' ? tlProfilesPureliteD : tlProfilesPurelite;
        let neededZuschnitt = installation === 'CT' ? ctZuschnittProfiles : lighting === 'direktstrahlend' ? tlDZuschnittProfiles : tlZuschnittProfiles;
        let neededProfileLengths =
            installation === 'CT' ? ctProfileLengths : lighting === 'direktstrahlend' ? tlProfilesPureliteDLengths : tlProfilesPureliteLengths;

        while (rest > 0) {
            for (let j = 0; j < neededProfileLengths.length; j++) {
                let currentValue = neededProfileLengths[j];
                if (rest - currentValue >= 0 && rest - currentValue > 500) {
                    profileProducts.push({
                        product: neededProfiles.filter((item) => item.length === currentValue)[0],
                        cut: null,
                        line: profile.line,
                    });
                    rest -= currentValue;
                    break;
                } else if (rest - currentValue >= 0 && rest - currentValue < 500) {
                    profileProducts.push({
                        product: neededProfiles.filter((item) => item.length === currentValue)[0],
                        cut: null,
                        line: profile.line,
                    });
                    rest -= currentValue;
                    break;
                }
            } // Purelite - 2004.1591 | Purelite D - 2004.1593
            if (rest < neededProfileLengths[neededProfileLengths.length - 1] && rest > 0) {
                if (rest > 2000) {
                    profileProducts.push({
                        product: neededZuschnitt.filter((item) => item.matchcode.includes('V2001-4203'))[0],
                        cut: add500 ? rest + 500 : rest,
                        line: profile.line,
                    });
                } else {
                    if (rest < 500) {
                        redoCalculation500Smaller = true;
                    } else {
                        profileProducts.push({
                            product: neededZuschnitt.filter((item) => item.matchcode.includes('V0-2000'))[0],
                            cut: add500 ? rest + 500 : rest,
                            line: profile.line,
                        });
                    }
                }
                if (redoCalculation500Smaller) {
                    // Reset to 500 smaller and redo calculation
                    rest = profile.profile - 500;
                    profileProducts = [];
                    redoCalculation500Smaller = false;
                    add500 = true;
                } else {
                    rest = 0;
                }
            }
        }
    }
    state.userProfiles = JSON.stringify(profileProducts);
    calculatePrices(state, profileProducts);
}
function mapEQPToProductsPurelite(state) {
    //products from ruleset
    let equipment = JSON.parse(state.equipment);
    let installation = state.userSelectedInstallation;
    let diffusor = state.userSelectedDiffusor;
    let lighting = state.userSelectedPureliteSlimLighting;
    let profileLengths = getCorrectProfileLengths(state);
    let totalProfileLength = 0;
    let isSemiRecessed = false; // TODO: Halbeinbau
    let selectedFixation = state.userSelectedFixation ? state.userSelectedFixation : false; // TODO
    let totalLength = 0; // Must be used
    let cover = false; // Line needs cover at each ends

    // Johannes wants recessed installation with edge cover (CT) and pendulum as well as ceiling without any (TL)
    switch (installation) {
        case 'ceiling':
        case 'pendulum':
            cover = true;
            break;
        case 'recessed':
            cover = false;
            break;
        default:
            console.log('An unexpected installation type was selected. TYPE:' + installation);
            break;
    }

    profileLengths.map((profile) => (totalProfileLength += profile.profile));

    //calculated products for user
    let userEquipment = [];

    // ## Elements in both
    // Connector of profiles are added in system equipment
    //Einspeise-Set -->The disassembly tool PURLT CHAN-S EQP TOOL YE is included in this set --> needed separately
    userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP INFD-SET')[0]);

    // Fixation: Pendulum suspension set --> one on each line
    if (installation === 'pendulum') {
        userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP SUSP-KIT-2x1500')[0]); // Includes 2 cables and needed cable extras
        for (let i = 0; i < (totalProfileLength - 3000) / 1500; i++) {
            // TODO must use total length substract double the length because of the already included double set
            userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP SUSP-KIT-1500')[0]);
        }
    }
    // Fixation: fix set replaces slotted disks (optional) if montage is semi-recessed (every 1500mm)
    else if (isSemiRecessed && selectedFixation === 'set') {
        for (let i = 0; i < totalProfileLength / 1500; i++) {
            // TODO must use total length
            if (cover && lighting === 'direktstrahlend') {
                userEquipment.push(equipment.filter((item) => item.article_code === '1022.7192')[0]);
            } else {
                userEquipment.push(equipment.filter((item) => item.article_code === '1022.7191')[0]);
            }
        }
    }
    // Fixation: spring
    else if (installation === 'ceiling' && selectedFixation === 'spring') {
        for (let i = 0; i < totalProfileLength / 1000; i++) {
            // TODO must use total length
            userEquipment.push(equipment.filter((item) => item.article_code === '1022.7195')[0]);
        }
    } else {
        // Default: Slotted disks every 1500mm - ceiling / recessed default
        let neededSuspensions = Math.ceil(totalProfileLength / 1500); // TODO TOTAL LENGTH
        for (let i = 0; i < neededSuspensions / 5; i++) {
            userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0]);
        }
        if (neededSuspensions % 5 !== 0) {
            neededSuspensions % 5 > 2
                ? userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 5PCS')[0])
                : userEquipment.push(equipment.filter((item) => item.article_code === 'PURLT EQP WSH-SLO-KIT 2PCS')[0]);
        }
    }

    // End of side sets 1x each line
    if (lighting === 'direktstrahlend') {
        if (installation === 'pendulum' || installation === 'ceiling') {
            // if (cover) {
            userEquipment.push(equipment.filter((item) => item.article_nr === '2004.6727')[0]);
            // }
        }
        if (installation === 'recessed') {
            userEquipment.push(equipment.filter((item) => item.article_nr === '2004.6728')[0]);
        }
    }

    // Diffusor cutting helper --> automatically selected if cut needed //TODO optional removable --> UI
    if (lighting === 'direktstrahlend') {
        userEquipment.push(equipment.filter((item) => item.article_nr === '2005.2934')[0]);
    } else {
        userEquipment.push(equipment.filter((item) => item.article_nr === '2001.5240')[0]);
    }

    // Diffusor connector all of Purelite D diffusors are rolling -> no connecting set needed
    if (lighting === 'raumstrahlend') {
        if (diffusor === 'runplus') {
            userEquipment.push(equipment.filter((item) => item.article_nr === '2002.6162')[0]); // Run-plus
        } else {
            userEquipment.push(equipment.filter((item) => item.article_nr === '2002.6161')[0]); // Opal
        }
    }

    state.userEQP = JSON.stringify(userEquipment);
    calculatePrices(state, userEquipment);
}

function mapSystemEquipmentToProductsPurelite(state) {
    let systemEquipment = JSON.parse(state.systemEquipment);
    let diffusorLengths = JSON.parse(state.diffusorLengths);
    let profiles = JSON.parse(state.userProfiles);
    let userSystemEquipment = [];
    let diffusor = state.userSelectedDiffusor.toLowerCase();
    let userDiffusors = [];

    //convert object into array to use filter method
    if (typeof systemEquipment === 'object') {
        let systemEquipmentIndexes = [];
        Object.keys(systemEquipment).forEach((key, index) => {
            systemEquipmentIndexes.push(key);
        });

        let temp = [];
        for (let i = 0; i < systemEquipmentIndexes.length; i++) {
            temp.push(systemEquipment[systemEquipmentIndexes[i]]);
        }
        systemEquipment = temp;
    }

    // Purelite with RUNPlus -> Office
    let diffusorRUN = systemEquipment.filter((item) => item.diffusor === 'RUN');
    let diffusorRUNPlus = systemEquipment.filter((item) => item.diffusor === 'RUN+');
    let diffusorOpal = systemEquipment.filter((item) => item.article_code.includes('PURLT SYS EQP DIF CW'));

    diffusorRUNPlus = diffusorRUNPlus.filter((item) =>
        item.article_code.includes(state.userSelectedPureliteSlimLighting === 'direktstrahlend' ? 'PURLTDC' : 'PURLTC')
    );

    diffusorRUN = diffusorRUN.sort((a, b) => a.length - b.length);
    diffusorRUNPlus = diffusorRUNPlus.sort((a, b) => a.length - b.length);
    diffusorOpal = diffusorOpal.sort((a, b) => a.length - b.length);

    let diffusorsForCalc = diffusor === 'run' ? diffusorRUN : diffusor === 'run+' ? diffusorRUNPlus : diffusorOpal;
    /** For room lighting: Johannes wants to mostly use diffusors with one edge cover -> 1EF and none with both like -> 2EF
    Because 2EF are only useful if they fit the installation perfectly. Therefore we disregard them.
    We should at least send 2x 1EF even for the smallest configuration and add 0EF if they are needed.
     */
    let diffusors1EF = diffusorsForCalc.filter((item) => item.article_code.includes('1EF'));
    let diffusorsOEF = diffusorsForCalc.filter((item) => item.article_code.includes('OEF'));

    // Diffusor
    let neededDifs = [];
    let diffusorsForCalcOnlyLenghts = [];
    let diffusorLength1EF = [];
    let diffusorLengthOEF = [];

    diffusorsForCalc.map((item) => diffusorsForCalcOnlyLenghts.push(item.length));
    diffusors1EF.map((item) => diffusorLength1EF.push(item.length));
    diffusorsOEF.map((item) => diffusorLengthOEF.push(item.length));

    // Different calculation based on selected ligting
    // --> Only room ligthing uses 1EF and OEF so differentiate between it and direct lighting
    let lighting = state.userSelectedPureliteSlimLighting;
    if (state.userSelectedPureliteSlimLighting === 'direktstrahlend') {
        for (let i = 0; i < diffusorLengths.length; i++) {
            let neededLength = diffusorLengths[i].length;

            while (neededLength > 0) {
                if (neededLength > diffusorsForCalcOnlyLenghts[diffusorsForCalcOnlyLenghts.length - 1]) {
                    neededDifs.push(diffusorsForCalc[diffusorsForCalcOnlyLenghts.length - 1]);
                    neededLength -= diffusorsForCalcOnlyLenghts[diffusorsForCalcOnlyLenghts.length - 1];
                } else {
                    for (let y = 0; y < diffusorsForCalc.length; y++) {
                        if (diffusorsForCalc[y].length >= neededLength) {
                            neededDifs.push(diffusorsForCalc[y]);
                            neededLength -= diffusorsForCalc[y].length;
                            break;
                        }
                    }
                }
            }
        }
    } else {
        for (let i = 0; i < diffusorLengths.length; i++) {
            let neededLength = diffusorLengths[i].length;
            let fittingIndex = diffusors1EF.length - 1;

            // First select the end pieces going from biggest to smallest, default is biggest
            for (let i = diffusors1EF.length - 1; i >= 0; i--) {
                if (2 * diffusors1EF[i].length >= neededLength) {
                    fittingIndex = i;
                }
            }

            // Add the 1EF for beginning and end
            neededDifs.push(diffusors1EF[fittingIndex]);
            neededDifs.push(diffusors1EF[fittingIndex]);
            neededLength -= diffusors1EF[fittingIndex].length * 2;

            // Fill the middle part of an installation bigger than the two biggest 1EF together
            while (neededLength > 0) {
                if (neededLength > diffusorsOEF[diffusorsOEF.length - 1].length) {
                    neededDifs.push(diffusorsOEF[diffusorsOEF.length - 1]);
                    neededLength -= diffusorsOEF[diffusorsOEF.length - 1].length;
                } else {
                    for (let y = 0; y < diffusorsOEF.length; y++) {
                        if (diffusorsOEF[y].length >= neededLength) {
                            neededDifs.push(diffusorsOEF[y]);
                            neededLength -= diffusorsOEF[y].length;
                            break;
                        }
                    }
                }
            }
        }
    }

    neededDifs.map((dif) => userDiffusors.push(dif));

    //Profilverbinder-Set (1022.0258)
    for (let i = 0; i < profiles.length - 1; i++) {
        userSystemEquipment.push(systemEquipment.filter((item) => item.article_code === 'PURLT SYS EQP LIN-CON-KIT')[0]);
    }

    state.userSystemEquipment = JSON.stringify(userSystemEquipment);
    calculatePrices(state, userSystemEquipment);

    state.userDiffusors = JSON.stringify(userDiffusors);
    calculatePrices(state, userDiffusors);
}

export function testPurelite(state) {
    let userLength = '0';
    let rulesetConstants = state.constants;
    let lineLengths = JSON.parse(state.lineLengths);

    // resetting prices, profileLengths & diffusorLengths
    // state.productPriceEur = 0;
    // state.productPriceChf = 0;
    // state.profileLengths = null;
    // state.diffusorLengths = null;

    //defining arrays for calculating elements
    let elements = [];

    let master = lineLengths.filter((item) => item.type === 'master');
    let slave = lineLengths.filter((item) => item.type === 'slave');

    //sort arrays after line length desc
    master.sort((a, b) => b.length - a.length);
    slave.sort((a, b) => b.length - a.length);

    userLength = '2500';

    // elements = calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "2500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "3500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "4500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "5500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "6500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "7500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "8500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "9500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "10500";
    // console.log("________________\nStart using: " + userLength)
    // calculateLinePurelite(state, userLength, rulesetConstants, master, slave, elements);
    // userLength = "11500";
    // console.log("________________\nStart using: " + userLength)
}

/**
 * Produces display value out for sidebar and set state profile label
 * @param {string} lineName Displayed in the canvas as LineNumber on the profile view used in LineLabel
 * @param {number} userLength
 * @param {number} userLength
 * @param {number} recess
 * @param {number} profileLength
 * @param {number} luminousSurface
 * @param {number} diffusorLength
 * @param {number} lightLength
 * @param {number} totalLength
 * @param state
 * @returns {array}
 */
function displayValues(lineName, userLength, recess, profileLength, luminousSurface, diffusorLength, lightLength, totalLength, state) {
    let lineCount = 1;
    let lineValues = [];
    let installation = current(state).userSelectedInstallation;

    // INTERNATIONALIZATION
    const cache = createIntlCache();
    const messages = {
        de: german,
        en: english,
        it: italian,
        fr: french,
    };

    const locale = state.language;

    const intl = createIntl({
        locale: locale,
        messages: messages[locale],
    });

    lineValues.push({ id: lineCount++, label: 'Name', value: lineName, suffix: '' });

    // Total length of this line
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.total.title'), value: totalLength, suffix: 'mm' });
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.diffusor.title'), value: diffusorLength, suffix: 'mm' });

    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.user.title'), value: userLength, suffix: 'mm' });
    if (false) {
        // DEBUG
        lineValues.push({ id: lineCount++, label: 'Luminous', value: luminousSurface, suffix: 'mm' });
        lineValues.push({ id: lineCount++, label: '[RAW] - Light', value: lightLength, suffix: 'mm' });
    }
    if (installation === 'recessed') {
        lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.recess'), value: recess, suffix: 'mm' });
    }

    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.profile.title'), value: profileLength, suffix: 'mm' });
    lineValues.push({ id: lineCount++, label: imperativeTranslateMessage(intl, 'canvas.right.length.light.title'), value: lightLength, suffix: 'mm' });

    // Set profile label
    //adding profileLength to array
    let profileLengths = JSON.parse(state.profileLengths) || [];
    profileLengths.push({ line: lineName, profile: profileLength });
    state.profileLengths = JSON.stringify(profileLengths);

    //adding diffusorLength to array
    let diffusorLengths = JSON.parse(state.diffusorLengths) || [];
    diffusorLengths.push({ line: lineName, length: diffusorLength });
    state.diffusorLengths = JSON.stringify(diffusorLengths);

    return lineValues;
}
