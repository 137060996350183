import axios from 'axios';
import handleError from './handleError';
import { getDialuxData } from './serviceDialux';
import form_i_hover from '../assets/icons/form/i-form_shadow.svg';
import form_i from '../assets/icons/form/i-form_no-shadow.svg';
import form_l_hover from '../assets/icons/form/l-form_shadow.svg';
import form_l from '../assets/icons/form/l-form_no-shadow.svg';
import form_s_hover from '../assets/icons/form/s-form_shadow.svg';
import form_s from '../assets/icons/form/s-form_no-shadow.svg';
import form_z_hover from '../assets/icons/form/z-form_shadow.svg';
import form_z from '../assets/icons/form/z-form_no-shadow.svg';
import form_u_hover from '../assets/icons/form/u-form_shadow.svg';
import form_u from '../assets/icons/form/u-form_no-shadow.svg';
import form_o_hover from '../assets/icons/form/o-form_shadow.svg';
import form_o from '../assets/icons/form/o-form_no-shadow.svg';
import recessed_hover from '../assets/icons/montage/icon-deckeneinbau_shadow.svg';
import recessed from '../assets/icons/montage/icon-deckeneinbau_no-shadow.svg';
import ceiling_hover from '../assets/icons/montage/icon-decken-wandmontage_shadow.svg';
import ceiling from '../assets/icons/montage/icon-decken-wandmontage_no-shadow.svg';
import pendulum_hover from '../assets/icons/montage/icon-pendelvariante_shadow.svg';
import pendulum from '../assets/icons/montage/icon-pendelvariante_no-shadow.svg';
import run from '../assets/diffusors/diffusor_channel-s.jpg';
import run_plus from '../assets/diffusors/diffusor_channel-s_office.jpg';
import boost from '../assets/diffusors/channel-s_boost-office.jpg';
import asymmetric from '../assets/diffusors/system-lichtleiste.jpg';
import run_plus_purelites from '../assets/diffusors/run+_purelite-slim.jpg';
import opal from '../assets/diffusors/opal_purelite-slim.jpg';
import pcsat from '../assets/diffusors/pcsat_flow.jpg';
import raumstrahlend from '../assets/icons/purelite-options/icon_raumstrahlend.svg';
import direktstrahlend from '../assets/icons/purelite-options/icon_direktstrahlend.svg';
import { selectMaxLengthConfiguration, setLinesCount, setUserLengths } from '../features/products/productsSlice';
import { setConfigurationLineLengths } from '../features/configuration/configurationSlice';
import IForm from '../components/LineInput/IForm';
import LForm from '../components/LineInput/LForm';
import SForm from '../components/LineInput/SForm';
import ZForm from '../components/LineInput/ZForm';
import UForm from '../components/LineInput/UForm';
import OForm from '../components/LineInput/OForm';
import channel_s from '../assets/product-teaser/teaser-channel-s-intro.png';
import channel_s_hover from '../assets/product-teaser/teaser-channel-s-intro-hover.png';
import channel_s_result from '../assets/product-teaser/teaser-channel-s-result.png';
import flow from '../assets/product-teaser/teaser-flow-intro.png';
import flow_hover from '../assets/product-teaser/teaser-flow-intro-hover.png';
import flow_result from '../assets/product-teaser/teaser-flow-result.png';
import purelite from '../assets/product-teaser/teaser-purelite-intro.png';
import purelite_hover from '../assets/product-teaser/teaser-purelite-intro-hover.png';
import purelite_result from '../assets/product-teaser/teaser-purelite-result.png';
import purelite_slim from '../assets/product-teaser/teaser-purelite-slim-intro.png';
import purelite_slim_hover from '../assets/product-teaser/teaser-purelite-slim-intro-hover.png';
import purelite_slim_result from '../assets/product-teaser/teaser-purelite-slim-result.png';
import purelite_slim_D_result from '../assets/product-teaser/teaser-purelite-slim-direct-lighting-result.png';
import { BASE_URL } from './domainService';
import { calculateLengthsPurelite, calculateLinePurelite } from '../features/products/purelite';

export const LOAD_RULESET_URL = BASE_URL + '/api/rulesets';
export const LOAD_PRODUCTS_URL = '/products';
export const LOAD_CHANNEL_S_LIGHTING_SYSTEM_URL = LOAD_PRODUCTS_URL + '/lighting-system-channel-s';
export const LOAD_PURELITE_SLIM_LIGHTING_SYSTEM_URL = LOAD_PRODUCTS_URL + '/lighting-system-purelite-slim';
export const LOAD_PURELITE_LIGHTING_SYSTEM_URL = LOAD_PRODUCTS_URL + '/lighting-system-purelite';
export const LOAD_FLOW_LIGHTING_SYSTEM_URL = LOAD_PRODUCTS_URL + '/lighting-system-flow';
export const LOAD_EQUIPMENT_URL = LOAD_PRODUCTS_URL + '/equipment';
export const LOAD_PROFILES_URL = LOAD_PRODUCTS_URL + '/profiles';
export const LOAD_SYSTEM_COMPONENTS_URL = LOAD_PRODUCTS_URL + '/system-components';
export const LOAD_SYSTEM_EQUIPMENT_URL = LOAD_PRODUCTS_URL + '/system-equipment';
export const MAX_LINE_LENGTH_CHAN_S = 18000;
export const MAX_LINE_LENGTH_CHAN_S_RUN = 24000;
export const MAX_LINE_LENGTH_CHAN_S_RUNPLUS = 19200;
export const MAX_LINE_LENGTH_CHAN_S_BOOST = 24000;
export const MAX_LINE_LENGTH_FLOW_PCSAT = 49800;
export const MAX_LINE_LENGTH_CHAN_S_ASYMMETRIC = 24000;
export const MAX_LINE_LENGTH_PURELITE_RUN_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITE_RUNPLUS_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITE_OPAL_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITED_RUN_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITED_RUNPLUS_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITED_OPAL_HPE = 19800;
export const MAX_LINE_LENGTH_PURELITE_RUN = 24000;
export const MAX_LINE_LENGTH_PURELITE_RUNPLUS = 24000;
export const MAX_LINE_LENGTH_PURELITE_OPAL = 24000;
export const MAX_LINE_LENGTH_PURELITED_RUN = 24000;
export const MAX_LINE_LENGTH_PURELITED_RUNPLUS = 24000;
export const MAX_LINE_LENGTH_PURELITED_OPAL = 24000;

export const MIN_LINE_LENGTH_CHAN_S_ASYMMETRIC = 1550;
export const MIN_LINE_LENGTH_CHAN_S_BOOST = 1850;
export const MIN_LINE_LENGTH_FLOW = 2400;
export const MIN_LINE_LENGTH_PURELITE = 2400;

export const POWER_WIRING_LENGTH = 1600;
export const LOAD_COMBINATIONS_URL = BASE_URL + '/api/combinations';
export const INSTALLATIONSMAPPE_URL = BASE_URL + '/api/installationmap';
export const RELUX_URL = BASE_URL + '/api/relux';
export const DIALUX_URL = BASE_URL + '/api/dialux';
export const SEND_MAIL_URL = BASE_URL + '/api/mail';
export const SWISSCODE = 'de-CH';

// Label size used in the canvas for everything but corners
export const bigLabelFontSize = 32;

/**
 * Loads ALL Rulesets
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<>>}
 */
export const loadRulesets = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads single Ruleset with given id
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadSingleRuleset = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads ALL Products
 * @param params
 * @returns {Promise<unknown>}
 */
export const loadProducts = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_PRODUCTS_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads Lighting Elements from Channel S
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadChannelSLightingSystem = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_CHANNEL_S_LIGHTING_SYSTEM_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads Lighting Elements from Purelite Slim
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadPureliteSlimLightingSystem = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_PURELITE_SLIM_LIGHTING_SYSTEM_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads Lighting Elements from Flow
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadFlowLightingSystem = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_FLOW_LIGHTING_SYSTEM_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads Lighting Elements from Purelite
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadPureliteLightingSystem = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_PURELITE_LIGHTING_SYSTEM_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads EQP Elements
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadEquipment = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_EQUIPMENT_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads Profiles
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadProfiles = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_PROFILES_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads SYS Elements
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadSystemComponents = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_SYSTEM_COMPONENTS_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads SYS EQP Elements
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadSystemEquipment = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_RULESET_URL + '/' + params.id + LOAD_SYSTEM_EQUIPMENT_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Loads ALL Combinations
 * @param params
 * @returns {Promise<import('axios').AxiosResponse<any,any>>}
 */
export const loadCombinations = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(LOAD_COMBINATIONS_URL, { params: params })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(handleError(error));
            });
    });
};

/**
 * Returns correct Ruleset Image
 * @param {string} rulesetName
 * @param {boolean} active
 * @param {string} pureliteSlimLighting
 * @param {string} screen Possible values intro or result. Intro is the squared - result the rectangular image.
 * @returns {*}
 */
export const renderRulesetImg = (rulesetName, active = false, pureliteSlimLighting = '', screen = 'intro') => {
    switch (rulesetName) {
        case 'Channel S':
            if (screen === 'result') {
                return channel_s_result;
            } else {
                return active ? channel_s_hover : channel_s;
            }
        case 'Purelite Slim':
            if (!pureliteSlimLighting) {
                if (screen === 'result') {
                    return purelite_slim_result;
                } else {
                    return active ? purelite_slim_hover : purelite_slim;
                }
            } else {
                if (screen === 'result' && pureliteSlimLighting === 'direktstrahlend') {
                    return purelite_slim_D_result;
                } else {
                    if (screen === 'result' && pureliteSlimLighting === 'raumstrahlend') {
                        return purelite_slim_result;
                    } else {
                        return purelite_slim_hover;
                    }
                }
            }
        case 'Flow':
            if (screen === 'result') {
                return flow_result;
            } else {
                return active ? flow_hover : flow;
            }
        case 'Purelite':
            if (screen === 'result') {
                return purelite_result;
            } else {
                return active ? purelite_hover : purelite;
            }
        default:
            break;
    }
};

/**
 * Returns Form Icon
 * @param shape
 * @param active
 * @returns {*}
 */
export const renderFormIcon = (shape, active = false) => {
    switch (shape) {
        case 'I':
            return active ? form_i_hover : form_i;
        case 'L':
            return active ? form_l_hover : form_l;
        case 'S':
            return active ? form_s_hover : form_s;
        case 'Z':
            return active ? form_z_hover : form_z;
        case 'U':
            return active ? form_u_hover : form_u;
        case 'O':
            return active ? form_o_hover : form_o;
        default:
            break;
    }
};

/**
 * Returns Installation Icon
 * @param mount
 * @param active
 * @returns {*}
 */
export const renderInstallationIcon = (mount, active = false) => {
    switch (mount) {
        case 'recessed':
            return active ? recessed_hover : recessed;
        case 'ceiling':
            return active ? ceiling_hover : ceiling;
        case 'pendulum':
            return active ? pendulum_hover : pendulum;
        default:
            break;
    }
};

/**
 * Returns Diffusor Icon
 * @param {string} diffusor
 * @param {string} currentRulesetName
 * @param {"raumstrahlend" | "direktstrahlend"} lightingDirection
 * @returns {string}
 */
export const renderDiffusorIcon = (diffusor, currentRulesetName, lightingDirection) => {
    switch (diffusor) {
        case 'run':
            return run;
        case 'run+':
            if (currentRulesetName === 'Purelite Slim' || currentRulesetName === 'Purelite') {
                if (lightingDirection === 'raumstrahlend') {
                    return run_plus;
                } else {
                    return run_plus_purelites;
                }
            } else {
                return run_plus;
            }
        case 'boost':
            return boost;
        case 'asymmetric':
            return asymmetric;
        case 'opal':
            return opal;
        case 'pcsat':
            return pcsat;
        default:
            return '';
    }
};

/**
 * Returns Installation Name
 * @param mount
 * @returns {string}
 */
export const renderInstallationText = (mount) => {
    switch (mount) {
        case 'recessed':
            return 'assembly.type.recessed';
        case 'ceiling':
            return 'assembly.type.ceiling';
        case 'pendulum':
            return 'assembly.type.pendulum';
        default:
            break;
    }
};

/**
 * Returns Installation Detail Text
 * @param mount
 * @returns {string}
 */
export const renderInstallationDetailText = (mount) => {
    switch (mount) {
        case 'recessed':
            return 'assembly.type.recessed.description';
        case 'ceiling':
            return 'assembly.type.ceiling.description';
        case 'pendulum':
            return 'assembly.type.pendulum.description';
        default:
            break;
    }
};

/**
 * Returns Diffusor Name
 * @param diffusor
 * @param currentRulesetName
 * @returns {string}
 */
export const renderDiffusorText = (diffusor, currentRulesetName) => {
    switch (diffusor.toLowerCase()) {
        case 'run':
            return 'design.type.run.title';
        case 'run+':
            if (currentRulesetName === 'Purelite') {
                return 'design.type.purelite.run+.title';
            } else {
                return 'design.type.run+.title';
            }
        case 'boost':
            return 'design.type.channels.boost.title';
        case 'asymmetric':
            return 'design.type.channels.asymmetric.title';
        case 'opal':
            if (currentRulesetName === 'Purelite Slim') {
                return 'design.type.pureliteslim.opal.title';
            } else {
                return 'design.type.purelite.opal.title';
            }
        case 'pcsat':
            return 'design.type.flow.pcsat.title';
        default:
            console.warn('Unknown light diffusor selected!');
            break;
    }
};

/**
 * Returns Diffusor Detail Text
 * @param diffusor
 * @param currentRulesetName
 * @returns {string}
 */
export const renderDiffusorDetailText = (diffusor, currentRulesetName) => {
    switch (diffusor.toLowerCase()) {
        case 'run':
            return 'design.type.channels.run.description';
        case 'run+':
            if (currentRulesetName === 'Purelite Slim') {
                return 'design.type.pureliteslim.run+.description';
            } else {
                return 'design.type.channels.run+.description';
            }
        case 'boost':
            return 'design.type.channels.boost.description';
        case 'asymmetric':
            return 'design.type.channels.asymmetric.description';
        case 'opal':
            return 'design.type.pureliteslim.opal.description';
        case 'pcsat':
            return 'design.type.flow.pcsat.description';
        default:
            console.warn('Unknown light diffusor selected!');
            break;
    }
};

/**
 * Returns Power Detail Text
 * @param power
 * @returns {string}
 */
export const renderPowerDetailText = (power) => {
    switch (power.toLowerCase()) {
        case 'xhe':
            return 'canvas.left.power.type.xhe.description';
        case 'he':
            return 'canvas.left.power.type.he.description';
        case 'hpe':
            return 'canvas.left.power.type.hpe.description';
        case 'ho':
            return 'canvas.left.power.type.ho.description';
        default:
            break;
    }
};

/**
 * Returns Purelite Slim Lighting Name
 * @returns {string}
 * @param lighting
 */
export const renderPureliteSlimLightingText = (lighting) => {
    switch (lighting) {
        case 'raumstrahlend':
            return 'light.direction.type.room.title';
        case 'direktstrahlend':
            return 'light.direction.type.direct.title';
        default:
            break;
    }
};

/**
 * Returns Purelite Slim Lighting Detail Text
 * @returns {string}
 * @param lighting
 */
export const renderPureliteSlimLightingDetailText = (lighting) => {
    switch (lighting) {
        case 'raumstrahlend':
            return 'light.direction.type.room.description';
        case 'direktstrahlend':
            return 'light.direction.type.direct.description';
        default:
            break;
    }
};

/**
 * Returns Purelite Slim Lighting Icon
 * @returns {*}
 * @param lighting
 * @param active
 */
export const renderPureliteSlimLightingIcon = (lighting) => {
    switch (lighting) {
        case 'raumstrahlend':
            return raumstrahlend;
        case 'direktstrahlend':
            return direktstrahlend;
        default:
            break;
    }
};

/** Takes the userProducts list, sums the elements and returns an array with every item and it's quantity
 * @param userProducts
 * @returns {*[]}
 */
export const calculateProductList = (userProducts) => {
    let productsWithQuantity = [];
    userProducts.forEach(function (product) {
        if (!this[product.id]) {
            this[product.id] = { product: product, quantity: 0 };
            productsWithQuantity.push(this[product.id]);
        }
        this[product.id].quantity += 1;
    }, Object.create(null));

    return productsWithQuantity;
};

export const calculateEquipmentWithQuantity = (equipment) => {
    let equipmentWithQuantity = [];
    equipment.forEach(function (product) {
        if (!this[product.id]) {
            this[product.id] = { product: product, quantity: 0 };
            equipmentWithQuantity.push(this[product.id]);
        }
        this[product.id].quantity += 1;
    }, Object.create(null));
    return equipmentWithQuantity;
};
export const calculateSystemEquipmentWithQuantity = (systemEquipment) => {
    let systemEquipmentWithQuantity = [];
    systemEquipment.forEach(function (product) {
        if (!this[product.id]) {
            this[product.id] = { product: product, quantity: 0 };
            systemEquipmentWithQuantity.push(this[product.id]);
        }
        this[product.id].quantity += 1;
    }, Object.create(null));
    return systemEquipmentWithQuantity;
};
export const calculateSystemComponentsWithQuantity = (systemComponents) => {
    let systemComponentsWithQuantity = [];
    systemComponents.forEach(function (product) {
        if (!this[product.id]) {
            this[product.id] = { product: product, quantity: 0 };
            systemComponentsWithQuantity.push(this[product.id]);
        }
        this[product.id].quantity += 1;
    }, Object.create(null));
    return systemComponentsWithQuantity;
};
export const calculateProfilesWithQuantity = (profiles) => {
    let profilesWithQuantity = [];

    let temp = [];
    profiles.forEach(function (product) {
        temp.push({ product: product.product, cut: product.cut, quantity: 1 });
    });

    temp.forEach(function (product) {
        let art = product.product;
        let art_nr = art.article_nr;
        let cut = product.cut;

        if (profilesWithQuantity.length) {
            let found = false;
            // eslint-disable-next-line array-callback-return
            profilesWithQuantity.map((el) => {
                if (el.product.article_nr === art_nr && el.cut === cut) {
                    el.quantity += 1;
                    found = true;
                }
            });
            if (!found) {
                profilesWithQuantity.push(product);
            }
        } else {
            profilesWithQuantity.push(product);
        }
    });

    return profilesWithQuantity;
};
export const calculateDiffusorsWithQuantity = (diffusors) => {
    let profilesWithQuantity = [];
    diffusors.forEach(function (product) {
        if (!this[product.id]) {
            this[product.id] = { product: product, quantity: 0 };
            profilesWithQuantity.push(this[product.id]);
        }
        this[product.id].quantity += 1;
    }, Object.create(null));
    return profilesWithQuantity;
};

/**
 * Precalculate allowed numbers list.
 Select a number:
 The number must be valid.
 Calculate nearest smaller valid number from the list.
 Show result as suggestion as well as its smaller and bigger neighbours.
 Do not show the numbers that do not exist.
 The configuration is always the same size or smaller than what was entered.
 *
 * @param {Array<int>} numbers preculated array containing all possible lengths
 * @param {int} value configuration length value entered by the user
 * @return {*}
 */
function findNearestSmallerSizeSuggestion(numbers, value) {
    let main = null;
    let smaller = null;
    let bigger = null;
    for (let i = 0; i < numbers.length; i++) {
        if (value >= numbers[i]) {
            smaller = numbers[i - 1] === undefined ? null : numbers[i - 1];
            bigger = numbers[i + 1] === undefined ? null : numbers[i + 1];
            main = numbers[i];
            continue;
        }
        // Handle case of input smaller than all possible options
        if (i === 0 && value < numbers[i]) {
            bigger = numbers[i] === undefined ? null : numbers[i];
            smaller = null;
            main = null;
            break;
        }
    }

    return { main: main, smaller: smaller, bigger: bigger };
}

function boostFilter(suggestion) {
    // These lengths are skipped since configuration is not possible
    let forbiddenLengths = [
        3350, 3650, 3950, 4250, 6350, 6650, 6950, 7250, 9350, 9650, 9950, 10250, 12350, 12650, 12950, 13250, 15350, 15650, 15950, 16250, 18350, 18650, 18950,
        19250, 21350, 21650, 21950, 22250, 24350, 24650, 24950, 25250,
    ];
    return !forbiddenLengths.includes(suggestion);
}

function asymmetricFilter(suggestion) {
    let forbiddenLengths = [2450, 3950, 5450, 6950, 8450, 9950, 11450, 12950, 14450, 15950, 17450, 18950, 20450, 21950, 23450, 24950];
    return !forbiddenLengths.includes(suggestion);
}

/**
 * Decides if the LengthPopup will be shown or not
 * @param showPopup function to set local state
 * @param minVal minimum value of that line
 * @param value current value
 * @param setSmaller function to set local state
 * @param setLine function to set local state
 * @param setBigger function to set local state
 */
export const showLengthPopup = (showPopup, minVal, value, setSmaller, setLine, setBigger, diffusor, ruleset, state) => {
    /*
	Mindestlängen (+ eine Zahl, damit beim späteren abrunden der Wert nicht unterschritten wird)
		Form I:
		- Linie 1: 600mm Master + 300mm Slave = 950

		Form L:
		- Linie 1: 600mm Master + 130mm Ecke = 760
		- Linie 2: 130mm Ecke + 100mm Slave = 260

		Form U/S/Z:
		- Linie 1: 600mm Master + 130mm Ecke = 760
		- Linie 2: 130mm Ecke + 100mm Slave + 600mm Master + 130mm Ecke + 5mm extra zwischen 2 Ecken = 975
		- Linie 3: 130mm Ecke + 100mm Slave = 260

		Form O:
		- Linie 1 - 4: 130mm Ecke + 100mm Slave + 600mm Master + 130mm Ecke + 5mm extra zwischen 2 Ecken = 975
	 */
    let val = parseInt(value);

    // Stop if entry was not a number
    if (isNaN(val) || val === 0) {
        showPopup(false);
        return;
    }
    showPopup(true);

    if (val < minVal) {
        setSmaller(null);
        setLine(minVal);
        setBigger(null);
    } else {
        let possibleLengths = [];
        let nextDistanceValue = 50; // Used to calculate valid entries for line lengths RUN/+ allows 50mm precision while boost and asymmetric have bigger slaves for extension

        if (ruleset === 'Purelite') {
            let step = 150; // TODO use rulesetConstants.step
            let max = state.maxLengthConfiguration; // TODO use rulesetConstants.max
            let userLength = val.toString();
            let rulesetConstants = state.constants;
            let lineLengths = JSON.parse(state.lineLengths);
            let lightLength = 0;

            //defining arrays for calculating elements
            let elements = [];

            let master = lineLengths.filter((item) => item.type === 'master');
            let slave = lineLengths.filter((item) => item.type === 'slave');

            //sort arrays after line length desc
            master.sort((a, b) => b.length - a.length);
            slave.sort((a, b) => b.length - a.length);

            let smallLengths = calculateLengthsPurelite(state, userLength, rulesetConstants, lightLength);
            let bigLengths = calculateLengthsPurelite(state, (parseInt(userLength) + step).toString(), rulesetConstants, lightLength);

            // Between possibles
            setSmaller(smallLengths.totalLength);
            setLine(val);
            setBigger(bigLengths.totalLength);

            // Smallest possible
            if (smallLengths.totalLength < minVal) {
                setSmaller(null);
            }

            // Biggest possible
            if (bigLengths.totalLength > max) {
                setBigger(null);
                if (smallLengths.totalLength === val) {
                    let newSmallLengths = calculateLengthsPurelite(state, (parseInt(userLength) - step).toString(), rulesetConstants, lightLength);
                    setSmaller(newSmallLengths.totalLength);
                }
            }
        }
        // else if (ruleset === "Flow") {
        // 	let step = 300; // TODO use rulesetConstants.step
        // 	let max = state.maxLengthConfiguration; // TODO use rulesetConstants.max
        // 	let userLength = val.toString();
        // 	let rulesetConstants = state.constants;
        // 	let lineLengths = JSON.parse(state.lineLengths);
        // 	let lightLength = 0;

        // 	//defining arrays for calculating elements
        // 	let elements = [];

        // 	let master = lineLengths.filter(item => item.type === 'master');
        // 	let slave = lineLengths.filter(item => item.type === 'slave');

        // 	//sort arrays after line length desc
        // 	master.sort((a, b) => b.length - a.length);
        // 	slave.sort((a, b) => b.length - a.length);

        // 	let smallLengths = calculateLengthsPurelite(state, userLength, rulesetConstants, lightLength);
        // 	let bigLengths = calculateLengthsPurelite(state, (parseInt(userLength) + step).toString(), rulesetConstants, lightLength);
        // 	// Between possibles
        // 	setSmaller(smallLengths.totalLength);
        // 	setLine(val);
        // 	setBigger(bigLengths.totalLength);

        // 	// Smallest possible
        // 	if (smallLengths.totalLength < minVal) {
        // 		setSmaller(null)
        // 	}
        // 	// Biggest possible
        // 	if (bigLengths.totalLength > max) {
        // 		setBigger(null)
        // 		if (smallLengths.totalLength === val) {
        // 			let newSmallLengths = calculateLengthsPurelite(state, (parseInt(userLength) - step).toString(), rulesetConstants, lightLength);
        // 			setSmaller(newSmallLengths.totalLength)
        // 		}
        // 	}
        // }
        else {
            if (diffusor === 'asymmetric' || diffusor === 'boost') {
                nextDistanceValue = diffusor === 'asymmetric' ? 300 : 300; // Smallest slaves boost 300; 600 assymmetric
                let remaining = MIN_LINE_LENGTH_CHAN_S_ASYMMETRIC;
                possibleLengths.push(remaining);
                while (remaining < MAX_LINE_LENGTH_CHAN_S_ASYMMETRIC) {
                    remaining += nextDistanceValue;
                    possibleLengths.push(remaining);
                }
                // Some lengths are impossible because of the smallest slave length of the type
                if (diffusor === 'asymmetric') {
                    possibleLengths = possibleLengths.filter(asymmetricFilter);
                }
                if (diffusor === 'boost') {
                    possibleLengths = possibleLengths.filter(boostFilter);
                }

                let suggestions = findNearestSmallerSizeSuggestion(possibleLengths, val);
                setSmaller(suggestions.smaller);
                setLine(suggestions.main);
                setBigger(suggestions.bigger);
                return;
            } else if (ruleset === 'Purelite Slim') {
                // Purelite Slim allows 25mm precision
                nextDistanceValue = 25;
            }

            let smaller = Math.floor(val / nextDistanceValue) * nextDistanceValue;
            let bigger = Math.ceil(val / nextDistanceValue) * nextDistanceValue;

            //only needed if input is even, because i.e. val = 1600 will be 1600 for smaller and bigger
            smaller = smaller === val ? smaller - nextDistanceValue : smaller;
            bigger = bigger === val ? bigger + nextDistanceValue : bigger;

            //if smaller is now smaller than the minVal, set it to null
            if (smaller < minVal) {
                smaller = null;
            }

            setSmaller(smaller);
            setLine(val);
            setBigger(bigger);
        }
    }
};

/**
 * Checks if input fields are set
 * @param dispatch dispatch function
 * @param linesCount count of line
 * @param line1Set is line 1 set?
 * @param line2Set is line 2 set?
 * @param line3Set is line 3 set?
 * @param line1Val value of line 1
 * @param line2Val value of line 2
 * @param line3Val value of line 3
 * @param line4Val value of line 4
 */
export const checkInputs = (
    dispatch,
    linesCount,
    line1Set = false,
    line2Set = false,
    line3Set = false,
    line1Val = null,
    line2Val = null,
    line3Val = null,
    line4Val = null
) => {
    if (!line1Set) return;
    if (!line2Set) return;
    if (!line3Set) return;

    let lines = {
        line1: line1Val,
        line2: line2Val,
        line3: line3Val,
        line4: line4Val,
    };

    dispatch(setLinesCount(linesCount));
    dispatch(setUserLengths(lines));
    dispatch(setConfigurationLineLengths(lines));
};

/**
 * Returns the correct LineInput-Element
 * @param currentForm
 * @returns {JSX.Element}
 */
export const renderLineInput = (currentForm) => {
    // eslint-disable-next-line default-case
    switch (currentForm) {
        case 'I':
            return <IForm />;
        case 'L':
            return <LForm />;
        case 'S':
            return <SForm />;
        case 'Z':
            return <ZForm />;
        case 'U':
            return <UForm />;
        case 'O':
            return <OForm />;
    }
};

/**
 * Center the drawing in the canvas
 * @param group
 * @param Viewer
 * @param padding
 */
export const handleCenterCanvas = (group, Viewer, padding = 100, form = null) => {
    const boundingBox = group.current.getBBox();

    //check if we have to change the zoom factor
    let zoomFactor = 1;
    let viewerWidth = Viewer.current.props.width - padding;
    let viewerHeight = Viewer.current.props.height - padding;
    let yFormOffset = form === 'U' || form === 'S' ? boundingBox.y : 0; // Needed for U if 3. lane is longer as the rest

    let factorX = viewerWidth / boundingBox.width;
    let factorY = viewerHeight / boundingBox.height;

    if (factorX < 1 && factorX <= factorY) {
        zoomFactor = factorX;
    } else if (factorY < 1 && factorY <= factorX) {
        zoomFactor = factorY;
    }

    Viewer.current.fitSelection(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height);
    Viewer.current.setPointOnViewerCenter(boundingBox.width / 2, boundingBox.height / 2 + yFormOffset, zoomFactor);
};

/**
 * Returns the minValue of a specific line
 * @param lineNumber
 * @param form
 * @returns {number}
 */
export const getMinValueOfLine = (lineNumber, form, diffusorType, ruleId) => {
    lineNumber = parseInt(lineNumber);
    // eslint-disable-next-line default-case
    switch (form) {
        case 'I':
            if (diffusorType === 'asymmetric') {
                return MIN_LINE_LENGTH_CHAN_S_ASYMMETRIC;
            } else if (diffusorType === 'boost') {
                return MIN_LINE_LENGTH_CHAN_S_BOOST;
            } else if (diffusorType === 'pcsat') {
                return MIN_LINE_LENGTH_FLOW;
            } else if (ruleId === 4) {
                return MIN_LINE_LENGTH_PURELITE;
            } else {
                return 950;
            }
            break;

        case 'L':
            if (lineNumber === 1) return 760;
            if (lineNumber === 2) return 260;
            break;
        case 'S':
            if (lineNumber === 1) return 1700;
            if (lineNumber === 2) return 975;
            if (lineNumber === 3) return 260;
            break;
        case 'Z':
            if (lineNumber === 1) return 1700;
            if (lineNumber === 2) return 975;
            if (lineNumber === 3) return 260;
            break;
        case 'U':
            if (lineNumber === 1) return 1700;
            if (lineNumber === 2) return 975;
            if (lineNumber === 3) return 260;
            break;
        case 'O':
            if (lineNumber === 1) return 975;
            if (lineNumber === 2) return 975;
            break;
    }
};

/**
 * Loads configuration from the datatabase. Takes code string and returns promise with response taking the form of {state: congfiguration_data, info: data}
 *
 * @export
 * @param {*} code
 * @return {*}
 */
export async function loadConfiguration(code) {
    return new Promise((resolve, reject) => {
        const LOAD_GET_CONFIG_URL = '/configurations/' + code.toString();

        //console.log("Send load request:");
        const apiClient = axios.create({
            baseURL: BASE_URL,
        });

        apiClient
            .get('/api' + LOAD_GET_CONFIG_URL)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error.message);
                if (error.response.status === 401) {
                    window.alert(
                        'Sie sind nicht berechtigt dieses Projekt aufzurufen. Wenn Sie sicher sind, dass Sie die benötigten Rechte besitzen, melden Sie neu an und versuchen Sie es erneut. Besteht das Problem weiterhin wenden Sie sich an unsere Kontaktadressen.'
                    );
                }
            });
    });
}

/**
 * Saves configuration in database. Needs the configuration data from the store.
 *
 * @export
 * @param {*} stateData
 * @return {*}
 */
export function saveConfiguration(stateData) {
    return new Promise((resolve, reject) => {
        const LOAD_Save_CONFIG_URL = '/configurations';
        //console.log("Send config save request:");

        const apiClient = axios.create({
            baseURL: BASE_URL,
        });
        let stateValue = stateData['configuration']; // Only save configuration related data
        apiClient
            .post(
                '/api' + LOAD_Save_CONFIG_URL,
                JSON.stringify({
                    info: '//TODO Placeholder: Info-state must be placed in store',
                    state: JSON.stringify(stateValue),
                })
            )
            .then(function (response) {
                console.log(response);
                resolve(response);
            })
            .catch(function (error) {
                console.log(error.message);
                reject(error.message);
                if (error.response.status === 401) {
                    window.alert(
                        'Sie sind nicht berechtigt dieses Projekt zu bearbeiten.\nWenn Sie sicher sind, dass Sie die benötigten Rechte besitzen, melden Sie neu an und versuchen Sie es erneut. \nBesteht das Problem weiterhin wenden Sie sich an unseren Support.'
                    );
                }
            });
    });
}

/**
 * Get the geolocation data of client. Used for retrieving the country code
 * @param params
 * @returns {Promise<{data:any}>}
 */
export const getGeolocationData = (params) => {
    console.log(window.navigator.language);
    return new Promise((resolve, reject) => {
        resolve({ data: { country_code: navigator.language } });
    });
    // Old geolocation service code
    // return new Promise((resolve, reject) => {
    // 	axios.get('https://geolocation-db.com/json/', { params: params })
    // 		.then(response => { resolve(response) })
    // 		.catch(error => { reject(handleError(error)) });
    // });
};

/**
 * Stores the corner rotations of every line
 * @type {{S: string[], U: string[], I: string[], Z: string[], L: string[], O: string[]}}
 */
export const ROTATION_OF_LINE_ELEMENTS = {
    I: [''],
    L: ['L'],
    U: ['L', 'L'],
    S: ['L', 'R'],
    Z: ['R', 'L'],
    O: ['R', 'R', 'R', 'R'],
};

/**
 * Some Rules where which diffusor is allowed
 * @param rulesetName
 * @param form
 * @param diffusor
 * @param currentPureliteSlimLighting
 * @returns {{css: string, isEnabled: boolean}}
 */
export function hideDiffusorsForSpecificForms(rulesetName, form, diffusor, currentPureliteSlimLighting) {
    if (rulesetName === 'Channel S') {
        if (form !== 'I') {
            if (diffusor === 'boost' || diffusor === 'asymmetric') {
                return { isEnabled: false, css: ' opacity-40 cursor-not-allowed hover:border-transparent' };
            }
        }
    } else if (rulesetName === 'Purelite Slim') {
        if (currentPureliteSlimLighting === 'direktstrahlend') {
            if (diffusor === 'run+' || diffusor === 'opal') {
                return { isEnabled: false, css: ' opacity-40 cursor-not-allowed hover:border-transparent' };
            }
        } else if (currentPureliteSlimLighting === 'raumstrahlend') {
            if (diffusor === 'run') {
                return { isEnabled: false, css: ' opacity-40 cursor-not-allowed hover:border-transparent' };
            }
        }
    } else if (rulesetName === 'Purelite') {
        if (currentPureliteSlimLighting === 'direktstrahlend') {
            if (diffusor === 'opal') {
                return { isEnabled: false, css: ' opacity-40 cursor-not-allowed hover:border-transparent' };
            }
        } else if (currentPureliteSlimLighting === 'raumstrahlend') {
            if (diffusor === 'run') {
                return { isEnabled: false, css: ' opacity-40 cursor-not-allowed hover:border-transparent' };
            }
        }
    }
    return { isEnabled: true, css: '' };
}

/**
 * Generates Data needed for InstallationMappe
 * @param userProducts
 * @param {string} form
 * @param {string} installation
 * @param diffusor
 * @param svg
 * @param configurationID
 * @param {string} projectName
 * @param {string} projectAuthor
 * @param color
 * @param power
 * @param lineLengths
 * @param {string} rulesetName
 * @param profileSVG
 * @param {string} ip
 * @param equipment
 * @param systemEquipment
 * @param systemComponents
 * @param profiles
 * @param diffusors
 * @param watt
 * @param lumen
 * @param projectBuilding
 * @param projectFloor
 * @param projectRoom
 * @param projectLine
 * @param lightingDesign
 * @param {string} language
 * @param {string} currency
 */
export const createInstallationMappe = (
    userProducts,
    form,
    installation,
    diffusor,
    svg,
    configurationID,
    projectName,
    projectAuthor,
    color,
    power,
    lineLengths,
    rulesetName,
    profileSVG,
    ip,
    equipment,
    systemEquipment,
    systemComponents,
    profiles,
    diffusors,
    watt,
    lumen,
    projectBuilding,
    projectFloor,
    projectRoom,
    projectLine,
    lightingDesign,
    language,
    currency
) => {
    let dataJSON = {};
    dataJSON.configurationID = configurationID;
    dataJSON.projectName = projectName;
    dataJSON.projectAuthor = projectAuthor;
    dataJSON.projectBuilding = projectBuilding;
    dataJSON.projectFloor = projectFloor;
    dataJSON.projectRoom = projectRoom;
    dataJSON.projectLine = projectLine;
    dataJSON.rulesetName = rulesetName;
    dataJSON.form = form;
    dataJSON.installation = installation;
    dataJSON.diffusor = diffusor;
    dataJSON.power = power;
    dataJSON.color = color;
    dataJSON.lineLengths = lineLengths;
    dataJSON.products = userProducts;
    dataJSON.ip = ip;
    dataJSON.equipment = equipment;
    dataJSON.systemEquipment = systemEquipment;
    dataJSON.systemComponents = systemComponents;
    dataJSON.profiles = profiles;
    dataJSON.profileSVG = profileSVG;
    dataJSON.electricalSVG = svg;
    dataJSON.diffusors = diffusors;
    dataJSON.watt = watt;
    dataJSON.lumen = lumen;
    dataJSON.lightingDesign = lightingDesign;
    dataJSON.language = language;
    dataJSON.currency = currency;

    return new Promise((resolve, reject) => {
        axios
            .post(INSTALLATIONSMAPPE_URL, JSON.stringify(dataJSON))
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error.message);
                reject(error.message);
            });
    });
};

/**
 * Generates Data needed for Relux
 * @param userProducts
 * @param diffusor
 * @param combinationProducts
 * @param configurationID
 * @param projectName
 * @param power
 * @param form
 */
export const createReluxDownload = (userProducts, diffusor, combinationProducts, configurationID, projectName, power, form) => {
    let additionalData = JSON.stringify(getReluxData(configurationID, projectName, userProducts, combinationProducts, diffusor, power, form));
    console.log(additionalData);
    return new Promise((resolve, reject) => {
        axios
            .post(RELUX_URL, additionalData)
            .then(function (response) {
                downloadULDOrRolfz(response.data, configurationID, 'rolfz');
                resolve(response);
            })
            .catch(function (error) {
                console.log(error.message);
                reject(error.message);
            });
    });
};

/**
 * Generates Data needed for Dialux
 * @param userProducts
 * @param diffusor
 * @param combinationProducts
 * @param installation
 * @param rulesetName
 * @param color
 * @param power
 * @param form
 * @param configurationID
 */
export const createDialuxDownload = (userProducts, diffusor, combinationProducts, installation, rulesetName, color, power, form, configurationID) => {
    // TW has adjustable Kelvin betweeen 2700 and 6500
    // Regent wants 4000K for Kelvin in uld TW files
    let colorDialux = { kelvin: color.kelvin, name: color.name };
    if (color.name === 'Tunable White') {
        colorDialux.kelvin = 4000;
    }
    let dataJSON = getDialuxData(installation, rulesetName, colorDialux.kelvin, userProducts, combinationProducts, diffusor, power, configurationID, form);
    let dataJSONString = JSON.stringify(dataJSON);

    console.log(JSON.stringify(dataJSON.dialuxData));
    return new Promise((resolve, reject) => {
        axios
            .post(DIALUX_URL, dataJSONString)
            .then(function (response) {
                downloadULDOrRolfz(response.data, configurationID, 'uld');
                resolve(response);
            })
            .catch(function (error) {
                console.log(error.message);
                reject(error.message);
            });
    });
};

/**
 * Generates data needed for AX XML
 * @param productsWithQuantity
 * @param projectName
 * @param configurationID
 */
export const createAXDownload = (
    productsWithQuantity,
    projectName,
    configurationID,
    building,
    floor,
    room,
    line,
    equipmentWithQuantity,
    systemEquipmentWithQuantity,
    systemComponentsWithQuantity,
    profilesWithQuantity,
    diffusorsWithQuantity
) => {
    //let name = projectName ? projectName : "Regent Lichtleisten Konfigurator";

    let articles = [];
    if (productsWithQuantity) {
        // eslint-disable-next-line array-callback-return
        productsWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;

            let data = {
                Article: {
                    Articlenumber: art_nr,
                    Quantity: quantity,
                },
            };

            articles.push(data);
        });
    }

    if (equipmentWithQuantity) {
        // eslint-disable-next-line array-callback-return
        equipmentWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;

            let data = {
                Article: {
                    Articlenumber: art_nr,
                    Quantity: quantity,
                },
            };

            articles.push(data);
        });
    }

    if (systemEquipmentWithQuantity) {
        // eslint-disable-next-line array-callback-return
        systemEquipmentWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;

            let data = {
                Article: {
                    Articlenumber: art_nr,
                    Quantity: quantity,
                },
            };

            articles.push(data);
        });
    }

    if (systemComponentsWithQuantity) {
        // eslint-disable-next-line array-callback-return
        systemComponentsWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;

            let data = {
                Article: {
                    Articlenumber: art_nr,
                    Quantity: quantity,
                },
            };

            articles.push(data);
        });
    }

    if (profilesWithQuantity) {
        // eslint-disable-next-line array-callback-return
        profilesWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;
            let cut = item.cut;
            let length = item.product.length;
            let data;

            if (cut) {
                data = {
                    Article: {
                        Articlenumber: art_nr,
                        Quantity: quantity,
                        Length: length,
                        // TODO: Changed attribute. Xavier gave the okay to use this name
                        'Cut-to': cut,
                    },
                };
            } else {
                data = {
                    Article: {
                        Articlenumber: art_nr,
                        Quantity: quantity,
                        Length: length,
                    },
                };
            }

            articles.push(data);
        });
    }

    if (diffusorsWithQuantity) {
        // eslint-disable-next-line array-callback-return
        diffusorsWithQuantity.map((item) => {
            let quantity = item.quantity;
            let art_nr = item.product.article_nr;

            let data = {
                Article: {
                    Articlenumber: art_nr,
                    Quantity: quantity,
                },
            };

            articles.push(data);
        });
    }

    let data = {
        Building: {
            Name: building,
            Floor: {
                Name: floor,
                Room: {
                    Name: room,
                    Line: {
                        Name: line,
                        Articles: articles,
                    },
                },
            },
        },
    };

    if (building === 'Unbenanntes Gebäude') {
        data.Building.Name = '';
    }
    if (floor === 'Unbenanntes Stockwerk') {
        data.Building.Floor.Name = '';
    }
    if (room === 'Unbenannter Raum') {
        data.Building.Floor.Room.Name = '';
    }
    if (line === 'Unbenannte Linie') {
        data.Building.Floor.Room.Line.Name = '';
    }

    var xml = '<?xml version="1.0"?>' + convertToXML(data);
    downloadFile(xml, configurationID, 'xml');
};

/**
 * Converts a nested javascript structure to a simple xml string.
 TODO: Escape invalid characters according to https://www.w3.org/TR/REC-xml/#syntax
 *
 * @param {*} data  should be a javascript data construct.
 * @return {*} a xml string
 */
function convertToXML(data) {
    var result = '';
    if (!Array.isArray(data)) {
        // Case of a object loop and take keys as attribute tags
        if (typeof data === 'object' && data !== null) {
            for (let key in data) {
                result += '<' + key + '>' + convertToXML(data[key]) + '</' + key + '>';
            }
        }
        // Case with a simple string or int
        else {
            return data;
        }
    }
    // In case of array use for of loop to access subitems directly
    else {
        for (let element of data) {
            result += convertToXML(element);
        }
    }
    return result;
}

/**
 * @param configurationID
 * @param {string} projectName
 * @param {{[p: string]: T}} lineItems
 * @param {string} combinationProducts
 * @param {string} diffusor
 * @param power
 * @param {number} form
 */
export const getReluxData = (configurationID, projectName, lineItems, combinationProducts, diffusor, power, form) => {
    let combiProducts = JSON.parse(combinationProducts);
    // let lineWidth = 65; //aus der excel entnommen
    let cornersMet = 0;
    let x = 0;
    let y = 0;
    //orientation = orientation of element
    let orientation = 0;
    //rotation = rotation of drawing
    let rotation = 0;

    let data = {
        article_number: configurationID,
        product_name: projectName ? projectName : 'Regent Lichtleisten Konfigurator',
        description: '',
        assembly: [],
    };

    Object.entries(lineItems).map(([key, item]) => {
        let combinationProduct = combiProducts.filter(
            (el) => el.article_code === item.article_code && el.power === power.name && el.diffusor.toLowerCase() === diffusor
        );
        let reluxIdentifier = '';
        if (combinationProduct.length) {
            reluxIdentifier = combinationProduct[0].article_code + combinationProduct[0].relux_code;
        } else {
            reluxIdentifier = item.article_code;
        }
        console.log('Orientation at the start of the object examination is: ' + orientation);

        let lineLength = item.type === 'corner' ? item.length - 0 : item.length;
        let transformation;

        let currentRotationOfLineElement = ROTATION_OF_LINE_ELEMENTS[form][cornersMet - 1];

        // Moves the 1. part of the length of the element
        // --> Checks the total current rotation at this segment
        if (!currentRotationOfLineElement | (rotation === 0)) {
            y -= lineLength / 2;
        } else if (rotation === 90) {
            x -= lineLength / 2;
        } else if (rotation === 270) {
            x += lineLength / 2;
        } else if (rotation === 180) {
            y += lineLength / 2;
        }

        if (item.type === 'corner') {
            cornersMet++;
            // NextCorner is
            if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet - 1] === 'L') {
                orientation -= 90;
                rotation -= 90;
            } else {
                orientation += 90;
                rotation += 90;
            }

            orientation = (orientation + 360) % 360;
            // orientation = orientation === 270 ? orientation = 90 : orientation === 180 ? orientation = 0 : orientation;

            rotation = (rotation + 360) % 360;

            transformation = [{ 'position.x': x / 1000 }, { 'position.y': y / 1000 }, { 'rotation.z': 0 }];

            // Remap the orientation for the corners since they bring their own rotation standard. ╔ is at 0°
            // Differentiate between "R"ightsided (else) and "L"eftsided corners
            if (ROTATION_OF_LINE_ELEMENTS[form][cornersMet - 1] === 'L') {
                // ╔ --> ╝
                if (orientation === 0) {
                    transformation[2]['rotation.z'] = 0;
                }
                // ╚ --> ╗
                else if (orientation === 90) {
                    transformation[2]['rotation.z'] = 270;
                }
                // ╝ --> ╔
                else if (orientation === 180) {
                    transformation[2]['rotation.z'] = 180;
                }
                // ╗ --> ╚
                else if (orientation === 270) {
                    transformation[2]['rotation.z'] = 90;
                }
            } else {
                if (orientation === 0) {
                    transformation[2]['rotation.z'] = 270;
                }
                // ╚ --> ╗
                else if (orientation === 90) {
                    transformation[2]['rotation.z'] = 180;
                }
                // ╝ --> ╔
                else if (orientation === 180) {
                    transformation[2]['rotation.z'] = 90;
                }
                // ╗ --> ╚
                else if (orientation === 270) {
                    transformation[2]['rotation.z'] = 0;
                }
            }
        } else {
            transformation = [{ 'position.x': x / 1000 }, { 'position.y': y / 1000 }, { 'rotation.z': orientation }];
        }

        // if (item.type === "corner") {
        data.assembly.push({
            article_number: item.article_nr,
            transformation: transformation,
            search_sub_key: reluxIdentifier.split('+EQP ')[1],
        });
        // }

        // Move current point for the second part of the element in the direction the element goes
        // ### IMPORTANT must differentiate for corners since they rotate in a different direction
        if (item.type === 'corner') {
            // Detect type of corner and move second part accordingly
            // ALL FOR L CORNERS
            if (orientation === 0) {
                y -= lineLength / 2;
            }
            // ╚ --> ╗ GUESS
            else if (orientation === 90) {
                x -= lineLength / 2;
            }
            // ╝ --> ╔ X
            else if (orientation === 180) {
                y += lineLength / 2;
            }
            // ╗ --> ╚ O
            else if (orientation === 270) {
                x += lineLength / 2;
            }
        } else {
            // Move current point for the second part of the element in the direction the rest of the side is heading
            if (!currentRotationOfLineElement | (rotation === 0)) {
                y -= lineLength / 2;
            } else if (rotation === 90) {
                x -= lineLength / 2;
            } else if (rotation === 270) {
                x += lineLength / 2;
            } else if (rotation === 180) {
                y += lineLength / 2;
            }
        }
    });

    //getting smallest X & Y value (< 0) and shifting x & y from negative to positive
    let smallestX = 0;
    let smallestY = 0;

    // eslint-disable-next-line array-callback-return
    data.assembly.map((item) => {
        let posX = item.transformation[0]['position.x'];
        let posY = item.transformation[1]['position.y'];

        if (posX < 0 && posX < smallestX) {
            smallestX = posX;
        }

        if (posY < 0 && posY < smallestY) {
            smallestY = posY;
        }
    });

    // eslint-disable-next-line array-callback-return
    data.assembly.map((item) => {
        let posX = item.transformation[0]['position.x'];
        let posY = item.transformation[1]['position.y'];

        item.transformation[0]['position.x'] = Math.round((posX + Math.abs(smallestX) + Number.EPSILON) * 100) / 100;
        item.transformation[1]['position.y'] = Math.round((posY + Math.abs(smallestY) + Number.EPSILON) * 100) / 100;
    });

    let returnValue = {};
    returnValue.configID = configurationID;
    returnValue.reluxData = data;

    data.assembly.forEach((value, index) => {
        var logElement = index + ' ';
        for (const element in value.transformation) {
            for (const property in value.transformation[element]) {
                logElement += ' ' + property + ' (' + value.transformation[element][property].toFixed(4) + ')';
            }
        }
        logElement += ' length' + ' (' + value.transformation.length.toFixed(4) + ')';
        console.log(logElement);
    });
    console.log(data);
    // throw Error;

    return returnValue;
};

/**
 * Creates a download element, clicks it and removes it
 * @param fileURL
 * @param configurationID
 */
export const downloadPDF = (fileURL, configurationID) => {
    //Preferred Version, but CORS Error
    /*fetch(BASE_URL + fileURL, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/pdf',
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`FileName.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	 */

    const link = document.createElement('a');
    link.href = BASE_URL + fileURL;
    link.setAttribute('download', 'regent-konfigurator-' + configurationID + '-installationsmappe.pdf');
    link.setAttribute('target', '_blank');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
};

/**
 * Creates a download element, clicks it and removes it
 * @param fileURL
 * @param configurationID
 */
export const downloadULDOrRolfz = (fileURL, configurationID, fileType) => {
    const link = document.createElement('a');
    link.href = BASE_URL + fileURL;
    link.setAttribute('download', 'regent-konfigurator-' + configurationID + '.' + fileType);
    link.setAttribute('target', '_blank');

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
};

/**
 * Creates a download element, clicks it and removes it
 * @param {*} data
 * @param {string} configurationID
 * @param {string} fileType
 * @param isInstallationsMappe
 */
const downloadFile = (data, configurationID, fileType, isInstallationsMappe = false) => {
    const element = document.createElement('a');
    let file = '';
    if (fileType === 'pdf') {
        file = new Blob([data], { type: 'application/octetstream' });
    } else {
        file = new Blob([data], { type: 'text/xml' });
    }
    element.href = URL.createObjectURL(file);
    element.download = 'regent-konfiguration-' + configurationID + (isInstallationsMappe ? '-installationsmappe' : '') + '.' + fileType;
    document.body.appendChild(element);
    element.click();
    element.remove();
};

/**
 * Sends formData to Laravel where an email is sent
 * @param {string|D} formData
 */
export const sendMail = (formData) => {
    return new Promise((resolve, reject) => {
        axios
            .post(SEND_MAIL_URL, formData)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error.message);
            });
    });
};

export function addThousandsSeparator(val, thousandSeparator) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}
